export default {
  primary: "#00B45D",
  secondary: "#2d7dd2",
  red: "#e93800",
  white: "#ffffff",
  background: "#f2f2f2",
  greyText: "#999999",
  border: "#d7d7d7",
  black: "#333333",
  darkGreyText: "#666666",
};
